import React from "react";
import { Grid, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Main } from "layouts";
import Container from "components/Container";
import imageSrc from "images/LeftScreen.webp";
import bgUrl from "images/hero-header/staticBackground.svg";
import LoginComponent from "components/LoginComponent";

const joinWaitlist = () => {
  // Using Material-UI hooks for theme and media
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Main>
      <Box sx={{ backgroundImage: `url(${bgUrl})`, width: "100%" }}>
        <Container pt={10}>
          {/* image and form grid */}
          <Grid container spacing={0}>
            {isMd && (
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  bgcolor: "#F6F9FF",
                  borderRadius: "30px 0 0 30px",
                  overflow: "hidden",
                }}
              >
                <Box
                  component="img"
                  height="100%"
                  width= "100%"
                  sx={{
                    maxHeight: "100%",
                    objectFit: "cover",
                    marginLeft: "-50px",
                    transform: "scale(1.2)",
                  }}
                  src={imageSrc}
                  alt="Appnigma ai login"
                  loading="lazy"
                />
              </Grid>
            )}
            {/* form component */}
            <Grid item xs={12} md={6} bgcolor={"white"} borderRadius={isMd ? "0 30px 30px 0" : "30px"}>
              <Box
                sx={{
                  padding: { xs: "60px 20px", md: "80px 46px" },
                  position: "relative",
                }}
                alignItems="center"
              >
                <LoginComponent />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Main>
  );
};

export default joinWaitlist;
